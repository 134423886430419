import React, {
  Suspense,
  Fragment,
  useState,
  useContext,
  useEffect,
} from 'react'
import { Router, Switch, Route } from 'react-router-dom'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { routes } from 'src/routes'
import { withStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switche from '@material-ui/core/Switch'
import { createBrowserHistory } from 'history'
import AuthContext from 'src/context/Auth'
import UserContext from 'src/context/User'
import PageLoading from 'src/component/PageLoading'
import AuthGuard from "src/component/AuthGuard";
import { createTheme } from 'src/theme'
import { ThemeProvider } from '@material-ui/core'
import { createMuiTheme, makeStyles } from '@material-ui/core/styles'
import {
  Box,
  Typography,
  TextField,
  Grid,
  Button,
  Container,
  Link,
} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Brightness3Icon from '@material-ui/icons/Brightness3'
import Brightness7Icon from '@material-ui/icons/Brightness7'
import typography from 'src/theme/typography'
import _ from 'lodash'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import SettingsContext from 'src/context/SettingsContext'
const history = createBrowserHistory()

function App() {
  const themeSeeting = useContext(SettingsContext)
  const themes = createTheme({
    theme: themeSeeting.settings.theme,
  })
  return (
    <div className="App">
      <ToastContainer />
      <AuthContext>
        <UserContext>
          <ThemeProvider theme={themes}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Router history={history}>
                <RenderRoutes data={routes} />
              </Router>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </UserContext>
      </AuthContext>
    </div>
  )
}

export default App

function RenderRoutes(props) {
  return (
    <Suspense fallback={<PageLoading />}>
      <Switch>
        {props.data.map((route, i) => {
          const Component = route.component
          const Guard = route.guard ? AuthGuard : Fragment
          const Layout = route.layout || Fragment
          return (
            <Route
              exact={route.exact}
              key={i}
              path={route.path}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      <RenderRoutes data={route.routes} />
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          )
        })}
      </Switch>
    </Suspense>
  )
}
