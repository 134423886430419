import React from 'react'
import { makeStyles, Box, LinearProgress } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    background: theme.palette.background.dark1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 2000,
  },
  loader: {
    width: 300,
    maxWidth: '100%',
    margin: 'auto',
  },
  progressBar: {
    height: '3px',
  },
}))

export default function PageLoading() {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      {/* <Box width={300}><LinearProgress height={10} /></Box> */}
      <Box>
        <img
          src="/loader/Loade_2.gif"
          alt=""
          style={{
            width: '250px',
            marginTop: '-70px',
            marginLeft: '-119px',
          }}
        />
      </Box>
    </div>
  )
}
