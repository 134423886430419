export const NetworkContextName = 'NETWORK'
export const ACTIVE_NETWORK = 42
export const RPC_URL =
  'https://kovan.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'
export const mamukaTokenAddress = '0xBA7E660028c0BEf6F5c2ef82C7e4a51E0F3aeEaa'
export const NetworkDetails = [
  {
    chainId: '0x61',
    chainName: 'Smart Chain Network',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: ['https://data-seed-prebsc-1-s2.binance.org:8545/'],
    blockExplorerUrls: ['https://testnet.bscscan.com/'],
  },
]
export const BinanceAPIKey = 'QAX8WIAD8NE5DTB2719UYIPFSVNNVS9CXH'
export const EtherScanAPIKey = 'H3B5JWXYNSATZI6R8ZVVQRNUDS8NTFF7WN'
export const PolygonScanAPIKEy = '8M24TG1DA4IXBNGWDK3TI8YJA7UPJ7FDDF'
