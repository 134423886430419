import { Box } from '@material-ui/core'
import React from 'react'

const Logo = (props) => {
  return (
    <Box>
      <img
        src="/images/logo/logo-shape.svg"
        alt="Logo"
        {...props}
        // style={{ height: '62px', width: '62px', padding: '0px 14px' }}
        className="appLogo"
      />
    </Box>
  )
}

export default Logo
