import { Contract } from '@ethersproject/contracts'
import { RPC_URL } from 'src/constants'
import Web3 from 'web3'
export function sortAddress(add) {
  const sortAdd = `${add.slice(0, 6)}...${add.slice(add.length - 4)}`
  return sortAdd
}
// export funtion currenyConvertor(value){
//   var formatter = new Intl.NumberFormat('en-US', {
//     style: 'currency',
//     currency: 'USD',

//   });

// }

export function getSigner(library, account) {
  return library.getSigner(account).connectUnchecked()
}

export function getProviderOrSigner(library, account) {
  return account ? getSigner(library, account) : library
}

export function getContract(address, ABI, library, account) {
  return new Contract(address, ABI, getProviderOrSigner(library, account))
}

export const getWeb3Provider = async () => {
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL)
  return httpProvider
}

export const getWeb3Obj = async () => {
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL)
  const web3 = await new Web3(httpProvider)
  return web3
}

export const getWeb3ContractObject = async (abi, contractAddress) => {
  const web3 = await getWeb3Obj()
  const contract = await new web3.eth.Contract(abi, contractAddress)
  return contract
}

export const getBalanceOf = async (abi, address, account) => {
  try {
    const contract = await getWeb3ContractObject(abi, address)
    const balanceOf = await contract.methods.balanceOf(account).call()
    return balanceOf.toString()
  } catch (error) {
    console.log('ERROR', error)

    return 0
  }
}

export const getBase64 = (file, cb) => {
  let reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = function () {
    cb(reader.result)
  }
  reader.onerror = function (err) {
    console.log('Error: ', err)
  }
}

export function isUrlValid(userInput) {
  var res = userInput.match(
    /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i,
  )
  if (res == null) return false
  else return true
}

export function isUrlValidTelegram(userInput) {
  var res = userInput.match(
    /https?:\/\/(t(elegram)?\.me|telegram\.org)\/([A-Za-z0-9\_]{5,32})\/?/g, // eslint-disable-line no-useless-escape
  )
  if (res == null) return false
  else return true
}

export function isValidEmail(value) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line no-useless-escape
  return re.test(String(value).toLowerCase())
}

export function isValidNumber(value) {
  const re = /^[0-9]*$/
  return re.test(value)
}
export function isValidName(value) {
  const re = /^[a-zA-Z]+$/ // eslint-disable-line no-useless-escape
  return re.test(String(value).toLowerCase())
}

