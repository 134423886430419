let baseurl = 'https://api.develocity.finance'
// let baseurl = 'https://blockchain-tool.mobiloitte.com'
let user = `${baseurl}/api/v1/user`
let admin = `${baseurl}/api/v1/admin`
let contract = `${baseurl}/api/v1/contract`
let static1 = `${baseurl}/api/v1/static`
// let static = `${baseurl}/api/v1/static`;

const apiConfig = {
  //USER
  login: `${user}/login`,
  register: `${user}/register`,
  profile: `${user}/profile`,
  addToken: `${user}/addToken`,
  tokenList: `${user}/tokenList`,
  resetPassword: `${user}/resetPassword`,
  forgotPassword: `${user}/forgotPassword`,
  userLatestContractScan: `${user}/userLatestContractScan`,
  getSubscriptionList: `${user}/getSubscriptionList`,
  buyPlan: `${user}/buyPlan`,
  getAdvertisement: `${user}/getAdvertisement`,
  contactUs: `${user}/contactUs`,
  scanPlan: `${user}/scanPlan`,
  viewAdvertisment1: `${user}/viewAdvertisment`,
  uploadKYCUser: `${user}/uploadKYC`,
  requestTokenKYC: `${user}/requestTokenKYC/`,
  checkKyc: `${user}/checkKyc`,
  getSponsor: `${user}/getSponsor`,
  checkAudit: `${user}/checkAudit`,
  suggestion: `${user}/suggestion`,
  viewToken: `${user}/viewToken/`,
  editToken: `${user}/editToken`,
  checkSubscription: `${user}/checkSubscription`,
  socialLogin: `${user}/socialLogin`,
  uploadFileUser: `${user}/uploadFile`,
  share: `${user}/share`,

  // STATIC
  viewWikiContent: `${static1}/viewWikiContent`,
  updateWikiContent: `${static1}/updateWikiContent`,
  addAnnouncement: `${static1}/addAnnouncement`,
  viewSocialLink: `${static1}/viewSocialLink`,
  updateSocialLink: `${static1}/updateSocialLink`,
  viewBitcoMine: `${static1}/viewBitcoMine`,
  updateBitcoMine: `${static1}/updateBitcoMine`,
  viewStaticContent: `${static1}/viewStaticContent`,
  staticContent: `${static1}/staticContent`,
  listAnnouncement: `${static1}/listAnnouncement`,
  viewAnnouncement: `${static1}/viewAnnouncement?announcementId=`,
  deleteAnnouncement: `${static1}/deleteAnnouncement?announcementId=`,
  listHelpCategory: `${static1}/listHelpCategory`,
  addHelpCategory: `${static1}/addHelpCategory`,
  listHelpContent: `${static1}/listHelpContent`,
  addStaticContent: `${static1}/addStaticContent`,
  viewHelpCategory: `${static1}/viewHelpCategory`,
  deleteHelpContent: `${static1}/deleteHelpContent`,
  addHelpContent: `${static1}/addHelpContent`,
  viewHelpContent: `${static1}/viewHelpContent`,
  deleteHelpCategory: `${static1}/deleteHelpCategory`,
  editHelpCategory: `${static1}/editHelpCategory`,
  editHelpContent: `${static1}/editHelpContent`,
  staticContentList: `${static1}/staticContentList`,
  addSponsor: `${static1}/addSponsor`,
  viewSponsor: `${static1}/viewSponsor`,
  listSponsor: `${static1}/listSponsor`,
  deleteSponsor: `${static1}/deleteSponsor`,
  setSponserVisibility: `${static1}/setSponserVisibility`,
  editSponsor: `${static1}/editSponsor`,
  viewSponsor: `${static1}/viewSponsor`,

  //ADMIN

  checkSubscriptionStatus: `${admin}/checkSubscriptionStatus`,
  enableDisableSubscription: `${admin}/enableDisableSubscription`,
  addWallet: `${admin}/addWallet`,
  listWallet: `${admin}/listWallet`,
  createSubAdmin: `${admin}/addSubAdmin`,
  listSubAdmin: `${admin}/listSubAdmin`,
  deleteSubAdmin: `${admin}/deleteSubAdmin?subAdminId=`,
  updateSubAdmin: `${admin}/editSubAdmin`,
  viewSubAdmin: `${admin}/viewSubAdmin`,
  viewRequestToken: `${admin}/viewRequestToken/`,
  getTokenRequestList: `${admin}/getTokenRequestList`,
  approveTokenRequest: `${admin}/approveTokenRequest/`,
  rejectTokenRequest: `${admin}/rejectTokenRequest/`,
  getKycRequestList: `${admin}/getKycRequestList`,
  viewKyc: `${admin}/viewKyc/`,
  approveKyc: `${admin}/approveKyc/`,
  rejectKyc: `${admin}/rejectKyc/`,
  getSubscriptionList: `${admin}/getSubscriptionList`,
  updateSubscription: `${admin}/updateSubscription/`,
  viewSubscription: `${admin}/viewSubscription/`,
  activeBlockSubAdmin: `${admin}/activeBlockSubAdmin`,
  uploadFile: `${admin}/uploadFile`,
  listUser: `${admin}/listUser`,
  viewUser: `${admin}/viewUser`,
  addAdvertisment: `${admin}/addAdvertisment`,
  viewAdvertisment: `${admin}/viewAdvertisment`,
  latestContractScan: `${admin}/latestContractScan`,
  dashboard: `${admin}/dashboard`,
  editAdvertisment: `${admin}/editAdvertisment`,
  deleteAdvertisment: `${admin}/deleteAdvertisment?advertismentId=`,
  deleteUser: `${admin}/deleteUser?user_id=`,
  advertisementList: `${admin}/advertisementList`,
  uploadKYC: `${admin}/uploadKYC`,
  getTokenRequestList: `${admin}/getTokenRequestList`,
  approveTokenRequest: `${admin}/approveTokenRequest/`,
  rejectTokenRequest: `${admin}/rejectTokenRequest/`,
  approveKYC: `${admin}/approveKYC/`,
  rejectKYC: `${admin}/rejectKYC/`,
  viewWallet: `${admin}/viewWallet/`,
  editWallet: `${admin}/editWallet`,
  adminTokenList: `${admin}/tokenList`,
  auditApprove: `${admin}/auditApprove`,
  editContract: `${admin}/editContract`,
  listLockedPlatform: `${admin}/listLockedPlatform`,
  viewLockedPlatform: `${admin}/viewLockedPlatform/`,
  addLockedPlatform: `${admin}/addLockedPlatform`,
  editLockedPlatform: `${admin}/editLockedPlatform`,
  deleteLockedPlatform: `${admin}/deleteLockedPlatform`,
  blockUnblockUser: `${admin}/blockUnblockUser`,
  listLiquidityWallet: `${admin}/listLiquidityWallet`,
  addLiquidityWallet: `${admin}/addLiquidityWallet`,
  viewLiquidityWallet: `${admin}/viewLiquidityWallet/`,
  editLiquidtyWallet: `${admin}/editLiquidtyWallet`,
  deleteLiquidityWallet: `${admin}/deleteLiquidityWallet`,

  //CONTRACT---SEARCH_API--->>>
  getPolygonContractDetails: `${contract}/getPolygonContractDetails`,
  getBSCContractDetail: `${contract}/getBSCContractDetail`,
  getETHContractDetails: `${contract}/getETHContractDetails`,
  BSCgetEstimateGasPrice: `${contract}/BSCgetEstimateGasPrice`,
  getBscLiquidityScan: `${contract}/getBscLiquidityScan`,
  getETHLiquidityScan: `${contract}/getETHLiquidityScan`,
  getPoygonLiquidityScan: `${contract}/getPoygonLiquidityScan`,
  getBSCTrasaction: `${contract}/getBSCTrasaction`,
  getETHTrasaction: `${contract}/getETHTrasaction`,
  BSCholderScan: `${contract}/BSCholderScan`,
  ETHholderScan: `${contract}/ETHholderScan`,
  polygonHolderScan: `${contract}/polygonHolderScan`,
  buySellBSCFeePercentage: `${contract}/buySellBSCFeePercentage`,
  buySellFeeETHPercentage: `${contract}/buySellFeeETHPercentage`,
  getBSCOwnerDetails: `${contract}/getBSCOwnerDetails`,
  getETHOwnerDetails: `${contract}/getETHOwnerDetails`,
  contractSniffed: `${contract}/contractSniffed`,
  contractSniffedView: `${contract}/contractSniffedView?contractId=`,
  BSCInfo: `${contract}/BSCInfo`,
  ETHInfo: `${contract}/ETHInfo`,
  PolygonInfo: `${contract}/PolygonInfo`,
  humanSummaryBSC: `${contract}/humanSummary`,
  ethHumanSummary: `${contract}/ethHumanSummary`,
  polygonHumanSummary: `${contract}/polygonHumanSummary`,
  auditReport: `${contract}/auditReport`,
  listWallet: `${contract}/listWallet`,
  lockedToken: `${contract}/lockedToken`,
  tokenInfo: `${contract}/tokenInfo`,
  requestContract: `${contract}/requestContract`,
}

export default apiConfig
