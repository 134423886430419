import React, { useState, useEffect, useContext } from 'react'
import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
  Button,
  ListItem,
  List,
} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Brightness3Icon from '@material-ui/icons/Brightness3'
import Brightness7Icon from '@material-ui/icons/Brightness7'
import SettingsContext from 'src/context/SettingsContext'
const useStyles = makeStyles((theme) => ({
  toggleButtonShell: {
    // border: '1px solid #000',
    borderRadius: '32px',
    width: '50px',
    // backgroundColor: '#000',
    height: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '4px 3px',
  },
  toggleButton: {
    border: '1px solid #000',
    borderRadius: '100%',
    display: 'flex',
    padding: '6px',
    cursor: 'pointer',
  },
}))
export default function ThemeButton() {
  const [theme, setTheme] = useState(false)
  const themeSeeting = useContext(SettingsContext)
  const classes = useStyles()
  const changeTheme = (type) => {
    themeSeeting.saveSettings({
      theme: type,
    })
    window.sessionStorage.setItem('theme', type)
  }
  useEffect(() => {
    if (window.sessionStorage.getItem('theme') === 'LIGHT') {
      setTheme(false)
    } else {
      setTheme(true)
    }
  }, [window.sessionStorage.getItem('theme')])

  return (
    <Box
      className={`${classes.toggleButtonShell}`}
      style={
        !theme
          ? { backgroundColor: '#fff' }
          : { backgroundColor: '#4c4949', border: '1px solid #000' }
      }
    >
      <IconButton
        // className={classes.toggleButton}
        style={
          theme
            ? { color: '#000', padding: '1px' }
            : { background: '#242538', padding: '1px' }
        }
      >
        <Brightness7Icon
          style={
            theme
              ? { color: '#000', fontSize: '20px' }
              : { color: 'rgb(244 144 24)', fontSize: '20px' }
          }
          onClick={() => {
            setTheme(false)
            changeTheme('LIGHT')
          }}
        />
      </IconButton>
      <IconButton
        // className={classes.toggleButton}
        style={
          !theme
            ? { color: '#000', padding: '1px' }
            : { background: '#000', padding: '1px' }
        }
      >
        <Brightness3Icon
          onClick={() => {
            setTheme(true)
            changeTheme('DARK')
          }}
          style={
            !theme
              ? {
                  color: '#000',
                  transitionDuration: '4s',
                  fontSize: '20px',
                }
              : {
                  color: '#ffc107',
                  transform: 'rotate(152deg)',
                  transitionDuration: '4s',
                  fontSize: '20px',
                }
          }
        />
      </IconButton>
    </Box>
  )
}
