import React, { createContext, useState, useEffect } from 'react'
import axios from 'axios'
import apiConfig from 'src/APIconfig/ApiConfig'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import { injected } from 'src/connectors'
import { useWeb3React } from '@web3-react/core'
import { ACTIVE_NETWORK, NetworkDetails } from 'src/constants'
import { getWeb3Obj } from 'src/utils'
import {
  BinanceAPIKey,
  EtherScanAPIKey,
  PolygonScanAPIKEy,
} from 'src/constants'
export const AuthContext = createContext()

const setSession = (userAddress) => {
  if (userAddress) {
    sessionStorage.setItem('userAddress', userAddress)
  } else {
    sessionStorage.removeItem('userAddress')
  }
}

function checkLogin() {
  const accessToken = window.sessionStorage.getItem('token')
  return accessToken ? true : false
}
function checkLoginAdmin() {
  const accessToken = window.sessionStorage.getItem('userType')
  return accessToken === 'Admin' ? true : false
}
export default function AuthProvider(props) {
  const { activate, account, chainId } = useWeb3React()
  const history = useHistory()
  const [isLogin, setIsLogin] = useState(checkLogin())
  const [isAdmin, setIsAdmin] = useState(checkLoginAdmin())
  const [isLoadingAds, setIsLoadingAds] = useState(false)
  const [userData, setUserData] = useState({})
  const [searchToken, setSearchToken] = useState('')
  const [searchNetwork, setSearchNetwork] = useState('')
  const [theme, setTheme] = useState(true)
  const [searchTokenDetails, setSearchTokenDetails] = useState([])
  const [networkGasPrice, setNetworkGasPrice] = useState([])
  const [networkLiquidity, setNetworkLiquidity] = useState([])
  const [networkHolders, setNetworkHolders] = useState([])
  const [networkTaxCal, setNetworkCal] = useState([])
  const [isLoadingSearchedData, setIsLoadingSearchedData] = useState(0)
  const [isSearchTrue, setIsSearchTrue] = useState(false)
  const [dashboardDetails, setDashboardDetails] = useState([])
  const [contractInitialData, setContractInitialData] = useState([])
  const [advertismnet, setAdvertisement] = useState([])
  const [humanSummary, setHumanSummary] = useState({})
  const [bscScanResult, setBscScanResult] = useState('')
  const [ethScanResult, setETHScanResult] = useState('')
  const [polygonScanResult, setPolygonScanResult] = useState('')
  const [networkCount, setNetworkcount] = useState(0)
  const [getBSCOwnerDetails, setGetBSCOwnerDetails] = useState([])
  const [networkWalletData, setNetworkWalletData] = useState([])
  const [yourWalletBalance, setYourWalletBalance] = useState(0)
  const [bscTransactionData, setBSCtransactionData] = useState([])
  const [bscDetailstokenTransaction, setBscDetailstokenTransaction] = useState(
    [],
  )
  const [socialLinks, setSocailLinks] = useState({})
  const [termsData, setTermsData] = useState({})
  const [bscDetailstransactions, setBscDetailstransactions] = useState([])
  const [policyData, setPolicyData] = useState({})
  const [sponsorData, setSponsorData] = useState([])
  const [liquidityDetails, setLiquidityDetails] = useState([])
  const [tokenInfoData, getTokenInfoData] = useState([])
  const [reload, setReload] = useState(false)
  const token = window.sessionStorage.getItem('token')
    ? window.sessionStorage.getItem('token')
    : ''
  const [networkGasFee, setNetworkGasFee] = useState('')
  const [isETHPolygon, setIsETHPolygon] = useState(false)
  //staticDataAPI
  const getSponsorDatahandler = async () => {
    try {
      const res = await axios({
        method: 'GET',
        url: apiConfig.getSponsor,
      })
      if (res.data.responseCode === 200) {
        console.log('result----', res.data.result)
        setSponsorData(res.data.result)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const geSocialLinksHandler = async () => {
    try {
      const res = await axios({
        method: 'GET',
        url: apiConfig.viewSocialLink,
      })
      if (res.data.responseCode === 200) {
        // console.log('resultSocialLinks===', res.data.result)
        setSocailLinks(res.data.result)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const getTermsAndConditionshandler = async () => {
    try {
      const res = await axios.get(apiConfig.viewStaticContent, {
        headers: {
          token: window.sessionStorage.getItem('token'),
        },
        params: {
          type: 'T&C',
        },
      })
      if (res.data.responseCode === 200) {
        setTermsData(res.data.result)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const policyApi = async () => {
    try {
      const res = await axios.get(apiConfig.viewStaticContent, {
        headers: {
          token: window.sessionStorage.getItem('token'),
        },
        params: {
          type: 'P&P',
        },
      })
      if (res.data.responseCode === 200) {
        setPolicyData(res.data.result)
      }
    } catch (err) {
      console.log(err)
    }
  }
  const getProfileHandler = async (accessToken) => {
    try {
      const res = await axios({
        method: 'GET',
        url: apiConfig.profile,
        headers: {
          token: accessToken,
        },
      })
      if (res.data.responseCode === 200) {
        console.log('profileUser', res.data.result)
        setUserData(res.data.result)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const getAdvertisementHandler = async (accessToken) => {
    try {
      setIsLoadingAds(true)
      const res = await axios({
        method: 'GET',
        url: apiConfig.getAdvertisement,
      })
      if (res.data.responseCode === 200) {
        setAdvertisement(res.data.result)
        setIsLoadingAds(false)
      }
    } catch (error) {
      console.log(error)
      setIsLoadingAds(false)
    }
  }
  const dashboard = async () => {
    try {
      const res = await axios.get(apiConfig.dashboard, {
        headers: {
          token: token,
        },
      })
      if (res.data.responseCode === 200) {
        // console.log('dashboard', res.data.result)
        setDashboardDetails(res.data.result)
      }
    } catch (error) {
      console.log(error)
      // toast.error('Something went wrong')
    }
  }

  //BSCScan
  const getBSCContractDetailHandler = async (searchKey) => {
    try {
      const res = await axios({
        method: 'POST',
        url: apiConfig.getBSCContractDetail,

        headers: {
          token: window.sessionStorage.getItem('token')
            ? window.sessionStorage.getItem('token')
            : '',
        },
        params: {
          limit: 10,
        },
        data: {
          contractAddress: searchKey,
        },
      })
      if (res.data.responseCode === 200) {
        setSearchTokenDetails(res.data.result)
        setIsLoadingSearchedData((prev) => prev + 20)
      }
    } catch (error) {
      console.log(error)
      // toast.error('You have entered a wrong address')
      setReload(true)
      setIsSearchTrue(false)
      setIsLoadingSearchedData((prev) => prev + 20)

      if (error?.response?.data?.responseCode !== 500) {
        getBSCContractDetailHandler(searchToken)
      }
    }
  }
  const BSCgetEstimateGasPriceHandler = async (searchKey) => {
    try {
      const res = await axios({
        method: 'GET',
        url: apiConfig.BSCgetEstimateGasPrice,
        headers: {
          token: window.sessionStorage.getItem('token')
            ? window.sessionStorage.getItem('token')
            : '',
        },
        params: {
          contractAddress: searchKey,
        },
      })
      if (res.data.responseCode === 200) {
        setNetworkGasPrice(res.data.result)
        setIsLoadingSearchedData((prev) => prev + 20)
      }
    } catch (error) {
      console.log(error)
      setReload(true)
      setIsLoadingSearchedData((prev) => prev + 20)

      if (error?.response?.data?.responseCode !== 500) {
        BSCgetEstimateGasPriceHandler(searchToken)
      }
    }
  }
  const getBscLiquidityScanHandler = async (searchKey) => {
    try {
      const credentials = {
        contractAddress: searchKey,
        // since: '2020-12-01T23:59:59',
        // till: '2022-01-07T23:59:59',
      }

      const res = await axios.post(apiConfig.getBscLiquidityScan, credentials, {
        headers: {
          token: window.sessionStorage.getItem('token')
            ? window.sessionStorage.getItem('token')
            : '',
          accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      if (res.data.responseCode === 200) {
        setNetworkLiquidity(res.data.result)
        setIsLoadingSearchedData((prev) => prev + 20)
      }
    } catch (error) {
      console.log(error)
      // toast.error('Something went wrong')
      setReload(true)
      setIsLoadingSearchedData((prev) => prev + 20)

      if (error?.response?.data?.responseCode !== 500) {
        getBscLiquidityScanHandler(searchToken)
      }
    }
  }
  const BSCholderScanHandler = async (searchKey) => {
    try {
      const res = await axios({
        method: 'GET',
        url: apiConfig.BSCholderScan,
        headers: {
          token: window.sessionStorage.getItem('token')
            ? window.sessionStorage.getItem('token')
            : '',
        },
        params: {
          contractAddress: searchKey,
        },
      })
      if (res.data.responseCode === 200) {
        setNetworkHolders(res.data.result)
        // setIsLoadingSearchedData((prev) => prev + 10)
      }
    } catch (error) {
      setReload(true)
      console.log(error)

      if (error?.response?.data?.responseCode !== 500) {
        BSCholderScanHandler(searchToken)
      }
    }
  }
  const BSCInfoHandler = async (searchKey) => {
    try {
      const res = await axios({
        method: 'GET',
        url: apiConfig.BSCInfo,
        headers: {
          token: window.sessionStorage.getItem('token')
            ? window.sessionStorage.getItem('token')
            : '',
        },
        params: {
          contractAddress: searchKey,
        },
      })
      if (res.data.responseCode === 200) {
        setContractInitialData(res?.data?.result)
        setIsLoadingSearchedData((prev) => prev + 10)
      }
    } catch (error) {
      console.log(error)
      setIsLoadingSearchedData((prev) => prev + 10)
      if (error?.response?.data?.responseCode !== 500) {
        BSCInfoHandler(searchToken)
      }
      setReload(true)
    }
  }
  const humanSummaryBSCHandler = async (searchKey) => {
    try {
      const res = await axios({
        method: 'POST',
        url: apiConfig.humanSummaryBSC,
        headers: {
          token: window.sessionStorage.getItem('token')
            ? window.sessionStorage.getItem('token')
            : '',
        },

        data: {
          contractAddress: searchKey,
        },
      })
      if (res.data.responseCode === 200) {
        setHumanSummary(res.data.result)
        setIsLoadingSearchedData((prev) => prev + 10)

        setIsSearchTrue(false)
      }
    } catch (error) {
      console.log(error)
      // toast.error('Something went wrong')
      setIsLoadingSearchedData((prev) => prev + 10)

      setReload(true)
      if (error?.response?.data?.responseCode !== 500) {
        humanSummaryBSCHandler(searchToken)
      }
    }
  }
  const getBSCOwnerDetailsHandler = async (searchKey) => {
    try {
      const res = await axios({
        method: 'GET',
        url: apiConfig.getBSCOwnerDetails,
        headers: {
          token: window.sessionStorage.getItem('token')
            ? window.sessionStorage.getItem('token')
            : '',
        },
        params: {
          contractAddress: searchKey,
        },
      })
      if (res.data.responseCode === 200) {
        // console.log('result****BSC***', res.data.result)
        setGetBSCOwnerDetails(res.data.result)
      }
    } catch (error) {
      console.log(error)
      setReload(true)
      if (error?.response?.data?.responseCode !== 500) {
        getBSCOwnerDetailsHandler(searchToken)
      }
    }
  }
  const getBSCTrasactionDetails = async (searchKey) => {
    try {
      const res = await axios({
        method: 'GET',
        url: apiConfig.getBSCTrasaction,
        headers: {
          token: window.sessionStorage.getItem('token'),
        },
        params: {
          contractAddress: searchKey,
        },
      })
      if (res.status === 200) {
        console.log('res.dataFilter', res.data.result)
        setBSCtransactionData(res.data.result)
        setIsLoadingSearchedData((prev) => prev + 25)
        setBscScanResult('')
        setBscDetailstokenTransaction(res.data.result.tokenTransaction)
        setBscDetailstransactions(res.data.result.transactions)
      }
    } catch (error) {
      console.log(error)
      setIsLoadingSearchedData((prev) => prev + 25)

      setReload(true)
      if (error?.response?.data?.responseCode !== 500) {
        getBSCTrasactionDetails(searchToken)
      }
    }
  }
  const buySellBSCFeePercentageHandler = async (searchKey) => {
    try {
      const res = await axios({
        method: 'GET',
        url: apiConfig.buySellBSCFeePercentage,
        headers: {
          token: window.sessionStorage.getItem('token')
            ? window.sessionStorage.getItem('token')
            : '',
        },
        params: {
          contractAddress: searchKey,
        },
      })
      if (res.data.responseCode === 200) {
        setNetworkCal(res.data.result)
      }
    } catch (error) {
      console.log(error)
      // toast.error('Something went wrong')

      setReload(true)
      if (error?.response?.data?.responseCode !== 500) {
        buySellBSCFeePercentageHandler(searchToken)
      }
    }
  }

  const gasFeeBinancehandler = async (searchKey) => {
    try {
      const res = await axios({
        methos: 'GET',
        url: `https://api.pancakeswap.info/api/v2/tokens/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c`,
      })
      if (res.status === 200) {
        console.log('response________----', res?.data?.data)

        const calciForBscGas = (5 / 10 ** 9) * res?.data?.data?.price
        setNetworkGasFee(calciForBscGas)
      }
    } catch (error) {
      console.log(error)
    }
  }
  //ETHScan
  const getETHContractDetailsHandler = async (searchKey) => {
    try {
      const res = await axios({
        method: 'GET',
        url: apiConfig.getETHContractDetails,
        headers: {
          token: window.sessionStorage.getItem('token')
            ? window.sessionStorage.getItem('token')
            : '',
        },
        params: {
          contractAddress: searchKey,
        },
      })
      if (res.data.responseCode === 200) {
        setSearchTokenDetails(res.data.result)
        console.log('isLoadingSearchedData----', isLoadingSearchedData)
        setIsLoadingSearchedData((prev) => prev + 35) //40
      }
    } catch (error) {
      console.log(error)
      setIsSearchTrue(false)
      setIsLoadingSearchedData((prev) => prev + 35)
      setReload(true)
      if (error?.response?.data?.responseCode !== 500) {
        getETHContractDetailsHandler(searchToken)
      }
    }
  }
  const getETHLiquidityScanHandler = async (searchKey) => {
    try {
      const credentials = {
        contractAddress: searchKey,
        // since: '2020-12-01T23:59:59',
        // till: '2022-01-07T23:59:59',
      }

      const res = await axios.post(apiConfig.getETHLiquidityScan, credentials, {
        headers: {
          token: window.sessionStorage.getItem('token')
            ? window.sessionStorage.getItem('token')
            : '',
          accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      if (res.data.responseCode === 200) {
        setNetworkLiquidity(res.data.result)
        setIsLoadingSearchedData((prev) => prev + 20) //60
      }
    } catch (error) {
      console.log(error)
      setIsLoadingSearchedData((prev) => prev + 20)
      setReload(true)
      if (error?.response?.data?.responseCode !== 500) {
        getETHLiquidityScanHandler(searchToken)
      }
    }
  }
  const ETHholderScanHandler = async (searchKey) => {
    try {
      const res = await axios({
        method: 'GET',
        url: apiConfig.ETHholderScan,
        headers: {
          token: window.sessionStorage.getItem('token')
            ? window.sessionStorage.getItem('token')
            : '',
        },
        params: {
          contractAddress: searchKey,
        },
      })
      if (res.data.responseCode === 200) {
        setNetworkHolders(res.data.result)
        // setIsLoadingSearchedData((prev) => prev + 20)
      }
    } catch (error) {
      console.log(error)
      if (error?.response?.data?.responseCode !== 500) {
        ETHholderScanHandler(searchToken)
      }
      setReload(true)
    }
  }
  const buySellFeeETHPercentageHandler = async (searchKey) => {
    try {
      const res = await axios({
        method: 'GET',
        url: apiConfig.buySellFeeETHPercentage,
        headers: {
          token: window.sessionStorage.getItem('token')
            ? window.sessionStorage.getItem('token')
            : '',
        },
        params: {
          contractAddress: searchKey,
        },
      })
      if (res.data.responseCode === 200) {
        setNetworkCal(res.data.result)
        setIsLoadingSearchedData((prev) => prev + 30) //90
      }
    } catch (error) {
      console.log(error)
      setIsLoadingSearchedData((prev) => prev + 30)
      if (error?.response?.data?.responseCode !== 500) {
        buySellFeeETHPercentageHandler(searchToken)
      }
      setReload(true)
    }
  }
  // const ETHInfoHandler = async (searchKey) => {
  //   try {
  //     const res = await axios({
  //       method: 'GET',
  //       url: apiConfig.ETHInfo,
  //       headers: {
  //         token: window.sessionStorage.getItem('token')
  //           ? window.sessionStorage.getItem('token')
  //           : '',
  //       },
  //       params: {
  //         contractAddress: searchKey,
  //       },
  //     })
  //     if (res.data.responseCode === 200) {
  //       setContractInitialData(res?.data?.result)
  //       setIsLoadingSearchedData((prev) => prev + 10)
  //     }
  //   } catch (error) {
  //     console.log(error)
  //     setIsLoadingSearchedData((prev) => prev + 10)
  //     if (error?.response?.data?.responseCode !== 500) {
  //       ETHInfoHandler(searchToken)
  //     }
  //     setReload(true)
  //   }
  // }
  const ethHumanSummaryHandler = async (searchKey) => {
    try {
      const res = await axios({
        method: 'POST',
        url: apiConfig.ethHumanSummary,
        headers: {
          token: window.sessionStorage.getItem('token')
            ? window.sessionStorage.getItem('token')
            : '',
        },
        data: {
          contractAddress: searchKey,
        },
      })
      if (res.data.responseCode === 200) {
        setHumanSummary(res.data.result)
        setIsSearchTrue(false)

        // setIsLoadingSearchedData((prev) => prev + 10)
      }
    } catch (error) {
      setReload(true)
      console.log(error)
      if (error?.response?.data?.responseCode !== 500) {
        ethHumanSummaryHandler(searchToken)
      }
    }
  }
  const getETHOwnerDetailsHandler = async (searchKey) => {
    try {
      const res = await axios({
        method: 'GET',
        url: apiConfig.getETHOwnerDetails,
        headers: {
          token: window.sessionStorage.getItem('token')
            ? window.sessionStorage.getItem('token')
            : '',
        },
        params: {
          contractAddress: searchKey,
        },
      })
      if (res.data.responseCode === 200) {
        // console.log('result****ETH***', res.data.result)
        setGetBSCOwnerDetails(res.data.result)
        setIsLoadingSearchedData((prev) => prev + 10) //100
        setETHScanResult('')
      }
    } catch (error) {
      console.log(error)
      setReload(true)
      setIsLoadingSearchedData((prev) => prev + 10)
      if (error?.response?.data?.responseCode !== 500) {
        getETHOwnerDetailsHandler(searchToken)
      }
    }
  }

  const gasFeeEthereumhandler = async () => {
    try {
      const res = await axios({
        method: 'GET',
        url: 'https://owlracle.info/eth/gas',
      })
      if (res.status === 200) {
        console.log('----res----', res?.data?.basseFee)
        const response = await axios({
          method: 'GET',
          url: `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=ethereum`,
        })
        if (response) {
          console.log('response******', response?.data[0]?.current_price)
          setNetworkGasFee(
            parseFloat(response?.data[0]?.current_price) *
              parseFloat(res?.data?.basseFee),
          )
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  //PolygonScan
  const getPolygonContractDetailsHandler = async (searchKey) => {
    try {
      const res = await axios({
        method: 'GET',
        url: apiConfig.getPolygonContractDetails,
        headers: {
          token: window.sessionStorage.getItem('token')
            ? window.sessionStorage.getItem('token')
            : '',
        },
        params: {
          contractAddress: searchKey,
        },
      })
      if (res.data.responseCode === 200) {
        setSearchTokenDetails(res.data.result)
        setIsLoadingSearchedData((prev) => prev + 45)
      }
    } catch (error) {
      console.log(error)
      // toast.error('You have entered a wrong address')
      // setIsLoadingSearchedData(0)
      setIsSearchTrue(false)

      setIsLoadingSearchedData((prev) => prev + 45)
      if (error?.response?.data?.responseCode !== 500) {
        getPolygonContractDetailsHandler(searchToken)
      }
      setReload(true)
    }
  }
  const getPoygonLiquidityScanHandler = async (searchKey) => {
    try {
      const credentials = {
        contractAddress: searchKey,
        // since: '2020-12-01T23:59:59',
        // till: '2022-01-07T23:59:59',
      }

      const res = await axios.post(
        apiConfig.getPoygonLiquidityScan,
        credentials,
        {
          headers: {
            token: window.sessionStorage.getItem('token')
              ? window.sessionStorage.getItem('token')
              : '',
            accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      if (res.data.responseCode === 200) {
        setNetworkLiquidity(res.data.result)
        setIsLoadingSearchedData((prev) => prev + 30)
      }
    } catch (error) {
      console.log(error)
      // setIsLoadingSearchedData(0)

      setReload(true)
      setIsLoadingSearchedData((prev) => prev + 30)
      if (error?.response?.data?.responseCode !== 500) {
        getPoygonLiquidityScanHandler(searchToken)
      }
    }
  }
  const polygonHolderScanHandler = async (searchKey) => {
    try {
      const res = await axios({
        method: 'GET',
        url: apiConfig.polygonHolderScan,
        headers: {
          token: window.sessionStorage.getItem('token')
            ? window.sessionStorage.getItem('token')
            : '',
        },
        params: {
          contractAddress: searchKey,
        },
      })
      if (res.data.responseCode === 200) {
        setNetworkHolders(res.data.result)
        // setIsLoadingSearchedData((prev) => prev + 10)
      }
    } catch (error) {
      console.log(error)
      // toast.error('Something went wrong')
      // setIsLoadingSearchedData(0)
      setReload(true)

      if (error?.response?.data?.responseCode !== 500) {
        polygonHolderScanHandler(searchToken)
      }
    }
  }
  const PolygonInfoHandler = async (searchKey) => {
    try {
      const res = await axios({
        method: 'GET',
        url: apiConfig.PolygonInfo,
        headers: {
          token: window.sessionStorage.getItem('token')
            ? window.sessionStorage.getItem('token')
            : '',
        },
        params: {
          contractAddress: searchKey,
        },
      })
      if (res.data.responseCode === 200) {
        setContractInitialData(res.data.result)
        setIsLoadingSearchedData((prev) => prev + 10)
      }
    } catch (error) {
      console.log(error)

      setIsLoadingSearchedData((prev) => prev + 10)
      setReload(true)
      if (error?.response?.data?.responseCode !== 500) {
        PolygonInfoHandler(searchToken)
      }
    }
  }
  const polygonHumanSummaryHandler = async (searchKey) => {
    try {
      const res = await axios({
        method: 'POST',
        url: apiConfig.polygonHumanSummary,
        data: {
          contractAddress: searchKey,
        },
      })
      if (res.data.responseCode === 200) {
        // console.log('polygonHumanSummary---->>>', res.data.result)
        setHumanSummary(res.data.result)
        setIsLoadingSearchedData((prev) => prev + 20)
        setIsSearchTrue(false)
        setPolygonScanResult('')
      }
    } catch (error) {
      console.log(error)
      // toast.error('Something went wrong')

      if (error?.response?.data?.responseCode !== 500) {
        polygonHumanSummaryHandler(searchToken)
      }
      setIsLoadingSearchedData((prev) => prev + 20)
      setReload(true)
    }
  }

  const gasFeePolygonHandler = async () => {
    try {
      const response = await axios({
        method: 'GET',
        url: 'https://owlracle.info/poly/gas',
      })
      if (response.data === 200) {
        const res = await axios({
          method: 'GET',
          url: ` https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=matic-network`,
        })
        if (res) {
          console.log(
            'response---Polygon',
            parseFloat(res?.data[0]?.current_price) *
              parseFloat(response?.data?.baseFee),
          )
          setNetworkGasFee(
            parseFloat(res?.data[0]?.current_price) *
              parseFloat(response?.data?.baseFee),
          )
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if (token) {
      getProfileHandler(token)
      setIsLogin(checkLogin())
      setIsAdmin(checkLoginAdmin())
      if (
        window.sessionStorage.userType === 'Admin' ||
        window.sessionStorage.userType === 'SubAdmin'
      ) {
        dashboard()
      }
    }
    getAdvertisementHandler()
    geSocialLinksHandler()
    getTermsAndConditionshandler()

    policyApi()
  }, [window.sessionStorage.getItem('token')])
  useEffect(() => {
    getSponsorDatahandler()
  }, [])

  //commonAPI
  const networkWalletAPIHandler = async (netowrk) => {
    try {
      const res = await axios({
        method: 'GET',
        url: apiConfig.listWallet,
        headers: {
          token: token,
        },
        params: {
          contractType: netowrk,
        },
      })
      if (res.data.responseCode === 200) {
        setNetworkWalletData(res.data.result)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const cronAPIHandler = async (address, type) => {
    try {
      const res = await axios({
        method: 'POST',
        url: apiConfig.requestContract,
        data: {
          contractAddress: address,
          contractType: type,
        },
      })
      if (res.data.responseCode === 200) {
        console.log('responseCron------', res.data.result)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const lockedTokenAPIHandler = async (network) => {
    try {
      const res = await axios({
        method: 'GET',
        url: apiConfig.lockedToken,
        headers: {
          token: window.sessionStorage.getItem('token')
            ? window.sessionStorage.getItem('token')
            : '',
        },
        params: {
          contractAddress: searchToken,
          contractType: network,
        },
      })
      if (res.data.responseCode === 200) {
        console.log('response----Liquidity', res.data.result)
        setLiquidityDetails(res.data.result)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const tokenInfoHandler = async (address, network) => {
    try {
      const res = await axios({
        method: 'GET',
        url: apiConfig.tokenInfo,
        headers: {
          token: window.sessionStorage.getItem('token'),
        },
        params: {
          contractType: network,
          contractAddress: address,
        },
      })
      if (res.data.responseCode === 200) {
        getTokenInfoData(res.data.result)
      }
    } catch (error) {
      console.log(error)
    }
  }
  let data = {
    userLoggedIn: isLogin,
    adminLoggedIn: isAdmin,
    userData,
    searchToken,
    theme,
    searchNetwork,
    searchTokenDetails,
    networkGasPrice,
    networkLiquidity,
    networkHolders,
    networkTaxCal,
    isLoadingSearchedData,
    isSearchTrue,
    dashboardDetails,
    contractInitialData,
    advertismnet,
    humanSummary,
    isLoadingAds,
    networkCount,
    bscScanResult,
    ethScanResult,
    polygonScanResult,
    getBSCOwnerDetails,
    networkWalletData,
    yourWalletBalance,
    bscDetailstokenTransaction,
    bscDetailstransactions,
    socialLinks,
    termsData,
    policyData,
    sponsorData,
    liquidityDetails,
    networkGasFee,
    tokenInfoData,
    reload,
    isETHPolygon,
    setTheme: (data) => setTheme(data),
    setSearchToken: (data) => setSearchToken(data),
    userLogIn: (type, data) => {
      setSession(data)
      setIsLogin(type)
      setIsAdmin(type)
    },
    getProfileHandler: (data) => getProfileHandler(data),
    setSearchNetwork: (data) => setSearchNetwork(data),
    setIsSearchTrue: (data) => setIsSearchTrue(data),
    setIsLoadingSearchedData: (data) => setIsLoadingSearchedData(data),
    getAdvertisementHandler: () => getAdvertisementHandler(),
    connectWallet: () => {
      activate(injected, undefined, true).catch((error) => {
        if (error) {
          activate(injected)
        }
      })
    },
    updateUser: (account) => {
      setSession(account)
    },
    setNetworkcount: (data) => setNetworkcount(data),
    setIsETHPolygon: (data) => setIsETHPolygon(data),
  }
  useEffect(() => {
    const userAddress = window.sessionStorage.getItem('userAddress')
    if (userAddress) {
      data.connectWallet()
      getUserbalce()
    }
  }, [])
  useEffect(() => {
    if (account && chainId) {
      if (chainId !== ACTIVE_NETWORK) {
        if (window.ethereum) {
          swichNetworkHandler()
        }
      }
    }
  }, [chainId, account])

  //walletSwitch
  const swichNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x' + ACTIVE_NETWORK.toString(16) }],
      })
    } catch (error) {
      console.log('ERROR', error)

      toast.warn(error.message)
      // if (error.code === 4902) {
      //   addNetworkHandler();
      // }
    }
  }
  const addNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: NetworkDetails,
      })
    } catch (error) {
      console.log('ERROR', error)
      toast.warn(error.message)
    }
  }

  const scanPlanAPIHandler = async (searchKey) => {
    try {
      const res = await axios({
        method: 'POST',
        url: apiConfig.scanPlan,
        headers: {
          token: window.sessionStorage.getItem('token')
            ? window.sessionStorage.getItem('token')
            : '',
        },
        data: {
          contractAddress: searchKey,
        },
      })
      if (res.data.responseCode === 200) {
        sourceCodeFinder()
      } else if (res?.data?.responseCode === 404) {
        toast.error(res?.data?.responseMessage)
      } else {
        toast.error(res?.data?.responseMessage)
      }
    } catch (error) {
      if (error?.response?.data?.responseCode === 404) {
        toast.error(`You don't have any subscription plan`)
        setSearchToken('')
        setIsSearchTrue(false)
      }
      if (error?.response?.data?.responseCode === 400) {
        toast.error(`You don't have any subscription plan. Please Login!!`)
        setSearchToken('')
        setIsSearchTrue(false)
      }
      console.log(error)
    }
  }
  const checkSubscriptionMouduleHandler = async (searchKey) => {
    try {
      const res = await axios({
        methos: 'GET',
        url: apiConfig.checkSubscription,
      })

      if (res.data.responseCode === 200) {
        if (res?.data?.subscriptionStatus?.isActive) {
          scanPlanAPIHandler(searchKey)
        } else {
          sourceCodeFinder()
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  // useEffect(() => {
  //   checkSubscriptionMouduleHandler()
  // }, [])

  const sourceCodeFinder = async () => {
    setIsLoadingSearchedData((prev) => prev + 5)
    let count = []
    try {
      const resBSC = await axios({
        method: 'GET',
        url: `https://api.bscscan.com/api?module=contract&action=getsourcecode&address=${searchToken}&apikey=${BinanceAPIKey}`,
      })

      if (resBSC.status === 200) {
        setIsSearchTrue(false)
        if (resBSC.data.status === '1') {
          if (resBSC?.data?.result[0]?.SourceCode !== '') {
            count.push(1)
            setBscScanResult(resBSC?.data?.result[0]?.SourceCode)
          }
        }

        const resETH = await axios({
          method: 'GET',
          url: `https://api.etherscan.io/api?module=contract&action=getsourcecode&address=${searchToken}&apikey=${EtherScanAPIKey}`,
        })
        if (resETH.status === 200) {
          setIsSearchTrue(false)
          if (resETH.data.status === '1') {
            if (resETH?.data?.result[0]?.SourceCode !== '') {
              setETHScanResult(resETH?.data?.result[0]?.SourceCode)
              count.push(2)
            }
          }

          const resPolyGon = await axios({
            method: 'GET',
            url: `https://api.polygonscan.com/api?module=contract&action=getsourcecode&address=${searchToken}&apikey=${PolygonScanAPIKEy}`,
          })
          if (resPolyGon.status === 200) {
            setIsSearchTrue(false)
            if (resPolyGon.data.status === '1') {
              if (resPolyGon?.data?.result[0]?.SourceCode !== '') {
                setPolygonScanResult(resPolyGon?.data?.result[0]?.SourceCode)
                count.push(3)
              }
            }
          }
          if (
            (resBSC?.data?.result[0]?.SourceCode === '' &&
              resETH?.data?.result[0]?.SourceCode === '' &&
              resPolyGon?.data?.result[0]?.SourceCode === '') ||
            (resPolyGon.data.status === '0' &&
              resETH.data.status === '0' &&
              resBSC.data.status === '0')
          ) {
            toast.error('Report not found!! Please submit your token request.')
            setIsLoadingSearchedData((prev) => prev - 5)
            setSearchToken('')
            setIsSearchTrue(false)
          }
        }
        setNetworkcount(count)
      }
    } catch (error) {
      console.log(error)
      setIsLoadingSearchedData((prev) => prev - 5)
    }
  }
  const getUserbalce = async () => {
    const web3 = await getWeb3Obj()
    const balance = await web3.eth.getBalance(account)
    const balanceImETH = await web3.utils.fromWei(balance)
    setYourWalletBalance(parseFloat(balanceImETH).toFixed(2))
  }
  useEffect(() => {
    // setIsETHPolygon(false)
    if (searchNetwork !== '') {
      if (searchNetwork === 'Polygon') {
        setIsETHPolygon(true)
        setPolygonScanResult('')
        // getPolygonContractDetailsHandler(searchToken)
        // getPoygonLiquidityScanHandler(searchToken)
        // polygonHolderScanHandler(searchToken)
        // // PolygonInfoHandler(searchToken)
        // polygonHumanSummaryHandler(searchToken)
        // networkWalletAPIHandler('Polygon')
        // lockedTokenAPIHandler('Polygon')
        // tokenInfoHandler(searchToken, 'Polygon')
        // gasFeePolygonHandler(searchToken)
        cronAPIHandler(searchToken, 'Polygon')
        setSearchNetwork('')
      } else if (searchNetwork === 'ETH') {
        setIsETHPolygon(true)
        setETHScanResult('')
        cronAPIHandler(searchToken, 'Ethereum')
        // getETHContractDetailsHandler(searchToken)
        // getETHLiquidityScanHandler(searchToken)
        // ETHholderScanHandler(searchToken)
        // buySellFeeETHPercentageHandler(searchToken)
        // // ETHInfoHandler(searchToken)
        // ethHumanSummaryHandler(searchToken)
        // getETHOwnerDetailsHandler(searchToken)
        // networkWalletAPIHandler('Ethereum')
        // lockedTokenAPIHandler('Ethereum')
        // tokenInfoHandler(searchToken, 'Ethereum')
        // gasFeeEthereumhandler()
        setSearchNetwork('')
      } else if (searchNetwork === 'BSC') {
        setBscScanResult('')
        getBSCContractDetailHandler(searchToken)
        BSCgetEstimateGasPriceHandler(searchToken)
        getBscLiquidityScanHandler(searchToken)
        buySellBSCFeePercentageHandler(searchToken)
        humanSummaryBSCHandler(searchToken)
        getBSCOwnerDetailsHandler(searchToken)
        getBSCTrasactionDetails(searchToken)
        networkWalletAPIHandler('Binance')
        tokenInfoHandler(searchToken, 'Binance')
        lockedTokenAPIHandler('Binance')
        // BSCInfoHandler(searchToken)
        gasFeeBinancehandler(searchToken)
        BSCholderScanHandler(searchToken)
        setSearchNetwork('')
        cronAPIHandler(searchToken, 'Binance')
      }
    }

    // checkSubscriptionMouduleHandler()
  }, [searchNetwork])

  useEffect(() => {
    if (searchToken !== '') {
      if (isSearchTrue) {
        // if (token) {
        window.sessionStorage.setItem('contractAddress', searchToken)
        checkSubscriptionMouduleHandler(searchToken)
        // } else {
        //   // toast.warn("Please login first!!");
        //   // history.push('/tokens-login')
        //   setIsSearchTrue(false)
        //   setIsLoadingSearchedData(0)
        // }
        // sourceCodeFinder()
      }
    }
  }, [searchToken, isSearchTrue, token])

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  )
}
