import React, { lazy } from 'react'
import { Redirect } from 'react-router-dom'
import HomeLayout from 'src/layouts/HomeLayout'
import DashboardLayout from 'src/layouts/DashboardLayout'
import LoginLayout from 'src/layouts/LoginLayout'

export const routes = [
  {
    exact: true,
    path: '/',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/Home')),
  },
  {
    exact: true,
    path: '/wiki',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/TableTokens/WikiPage')),
  },
  {
    exact: true,
    path: '/view-token',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/TableTokens/ViewTokenList')),
  },
  {
    exact: true,
    path: '/help-center',
    layout: DashboardLayout,
    component: lazy(() =>
      import('src/views/pages/Dashboard/StaticManagement/HelpCenterMangement'),
    ),
  },
  {
    exact: true,
    path: '/help-center-content',
    layout: DashboardLayout,
    component: lazy(() =>
      import('src/views/pages/Dashboard/StaticManagement/HelpCenterContent'),
    ),
  },
  {
    exact: true,
    path: '/add-new-category-list',
    layout: DashboardLayout,
    component: lazy(() =>
      import('src/views/pages/Dashboard/StaticManagement/HelpCategory'),
    ),
  },
  {
    exact: true,
    path: '/edit-help-category',
    layout: DashboardLayout,
    component: lazy(() =>
      import('src/views/pages/Dashboard/StaticManagement/EditHelpCategory'),
    ),
  },
  {
    exact: true,
    path: '/help-category-view',
    layout: DashboardLayout,
    component: lazy(() =>
      import('src/views/pages/Dashboard/StaticManagement/HelpCategoryView'),
    ),
  },
  {
    exact: true,
    path: '/help-content-view',
    layout: DashboardLayout,
    component: lazy(() =>
      import('src/views/pages/Dashboard/StaticManagement/HelpContentView'),
    ),
  },
  {
    exact: true,
    path: '/help-content-edit',
    layout: DashboardLayout,
    component: lazy(() =>
      import('src/views/pages/Dashboard/StaticManagement/HelpContentEdit'),
    ),
  },
  {
    exact: true,
    path: '/add-new-category',
    layout: DashboardLayout,
    component: lazy(() =>
      import('src/views/pages/Dashboard/StaticManagement/AddNewCategory'),
    ),
  },
  {
    exact: true,
    path: '/cms-wiki',
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/TableTokens/index')),
  },
  {
    exact: true,
    path: '/locked-token',
    layout: DashboardLayout,
    component: lazy(() =>
      import('src/views/pages/LockedToken/ListLockedPlatform'),
    ),
  },
  {
    exact: true,
    path: '/locked-liquidity',
    layout: DashboardLayout,
    component: lazy(() =>
      import('src/views/pages/LockedLiquidity/LiquidityWallet'),
    ),
  },
  {
    exact: true,
    path: '/full-list',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/FullList/index')),
  },
  {
    exact: true,
    path: '/tokens',
    // guard: true,
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/FlowChart/index')),
  },
  {
    exact: true,
    path: '/tokens-login',
    layout: HomeLayout,
    component: lazy(() =>
      import('src/views/pages/FlowChart/BlurFlowChart/index'),
    ),
  },
  {
    exact: true,
    path: '/faq',
    layout: HomeLayout,
    component: lazy(() =>
      import('src/views/pages/Dashboard/StaticManagement/User/FAQ'),
    ),
  },
  {
    exact: true,
    path: '/faq-details',
    guard: true,
    layout: HomeLayout,
    component: lazy(() =>
      import(
        'src/views/pages/Dashboard/StaticManagement/User/CategoriesDetails'
      ),
    ),
  },
  {
    exact: true,
    path: '/dashboard',
    guard: true,
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/Dashboard')),
  },
  {
    exact: true,
    path: '/walletaddress',
    guard: true,
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import('src/views/pages/Dashboard/WalletAdress/ListWalletAddress'),
    ),
  },
  {
    exact: true,
    path: '/adminpannel',
    guard: true,
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/Announcedadmin/index')),
  },
  {
    exact: true,
    path: '/viewpannel',
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/Announcedadmin/View')),
  },
  {
    exact: true,
    path: '/users',
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/Dashboard/Userlist')),
  },
  {
    exact: true,
    path: '/postnewannounced',
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/Announcedadmin/Post')),
  },
  {
    exact: true,
    path: '/updateAnnouncement',
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import('src/views/pages/Announcedadmin/UpdateAnnouncement'),
    ),
  },

  {
    exact: true,
    path: '/edit-users',
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/Dashboard/UserEdit')),
  },

  {
    exact: true,
    path: '/subscription',
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import('src/views/pages/Dashboard/Subscription/Subscription'),
    ),
  },
  {
    exact: true,
    path: '/user-subscription',
    layout: HomeLayout,
    component: lazy(() =>
      import('src/views/pages/Dashboard/Subscription/UserSubscription'),
    ),
  },

  {
    exact: true,
    path: '/add-subscription',
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import('src/views/pages/Dashboard/Subscription/AddSubscription'),
    ),
  },
  {
    exact: true,
    path: '/subscription-details',
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import('src/component/SubscriptionCardDetails')),
  },

  {
    exact: true,
    path: '/subscription-package',
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import('src/views/pages/Dashboard/Subscription/Package'),
    ),
  },

  {
    exact: true,
    path: '/contract-sniffed',
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import('src/views/pages/Dashboard/Contract/ContractSniffer'),
    ),
  },
  {
    exact: true,
    path: '/kyc-detail',
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/Dashboard/EditProfile')),
  },

  {
    exact: true,
    path: '/token-detail',
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/Dashboard/User/TokenDetail')),
  },

  {
    exact: true,
    path: '/sniffer-detail',
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import('src/views/pages/Dashboard/User/CoinStake/SnifferDetail'),
    ),
  },
  {
    exact: true,
    path: '/view-latestscan',
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/Dashboard/ViewLatestScan')),
  },

  {
    exact: true,
    path: '/user-list',
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import('src/views/pages/Dashboard/User/UserManagement'),
    ),
  },

  {
    exact: true,
    path: '/kyc-pending',
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/Kycpending/Tokentable1')),
  },
  {
    exact: true,
    path: '/view-profile',
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/Kycpending/Seeprofile')),
  },
  {
    exact: true,
    path: '/login',
    layout: LoginLayout,
    component: lazy(() => import('src/views/auth/LogIn')),
  },
  {
    exact: true,
    path: '/logo',
    layout: LoginLayout,
    component: lazy(() => import('src/component/Logo')),
  },
  {
    exact: true,
    path: '/signup',
    layout: LoginLayout,
    component: lazy(() => import('src/views/auth/signup/signup')),
  },
  {
    exact: true,
    path: '/forget-password',
    // guard:true,
    layout: LoginLayout,
    component: lazy(() => import('src/views/auth/forget-password/index')),
  },
  {
    exact: true,
    path: '/instrauctions',
    // guard:true,
    component: lazy(() => import('src/views/auth/forget-password-link/index')),
  },
  {
    exact: true,
    path: '/reset-password',
    // guard:true,{n}
    layout: LoginLayout,
    component: lazy(() => import('src/views/auth/reset-password/index')),
  },
  {
    exact: true,
    path: '/token-kyc',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/TokenKyc/index')),
  },
  {
    exact: true,
    path: '/white-paper',
    // guard:true,
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/WhitePaper')),
  },
  {
    exact: true,
    path: '/contact-us',
    // guard: true,
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/ContactUs')),
  },
  {
    exact: true,
    path: '/kycpending',
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/Kycpending')),
  },
  {
    exact: true,
    path: '/add-devices',
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/Token/Tokentable1')),
  },

  {
    exact: true,
    path: '/devicelist',
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/Devicelist')),
  },
  {
    exact: true,
    path: '/details',
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/Devicelist/Details')),
  },
  {
    exact: true,
    path: '/token',
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/Token/Tokentable1')),
  },
  {
    exact: true,
    path: '/kyc',
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/Dashboard/KYC/KycList')),
  },

  {
    exact: true,
    path: '/add-admin',
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/Dashboard/Admin/Index')),
  },
  {
    exact: true,
    path: '/add-admin1',
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/Dashboard/Admin/SubAdmin')),
  },

  {
    exact: true,
    path: '/static-management',
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import('src/views/pages/Dashboard/StaticManagement/Static'),
    ),
  },
  {
    exact: true,
    path: '/ads-management',
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/AddManagement/Index')),
  },
  {
    exact: true,
    path: '/view-add',
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/AddManagement/ViewAdd')),
  },
  {
    exact: true,
    path: '/view-sponsor',
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/AddManagement/ViewSponsor')),
  },
  {
    exact: true,
    path: '/add-platform',
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/LockedToken/Locked')),
  },
  {
    exact: true,
    path: '/add-liquidity',
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import('src/views/pages/LockedLiquidity/AddLiquidity'),
    ),
  },
  {
    exact: true,
    path: '/view-platform',
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/LockedToken/ViewPlatform')),
  },
  {
    exact: true,
    path: '/view-liquidity',
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import('src/views/pages/LockedLiquidity/ViewLiquidity/'),
    ),
  },
  {
    exact: true,
    path: '/add-add',
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/AddManagement/AddAdds')),
  },
  {
    exact: true,
    path: '/add-sponsor',
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/AddManagement/AddSponsor')),
  },
  {
    exact: true,
    path: '/edit-add',
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/AddManagement/EditAdd')),
  },

  {
    exact: true,
    path: '/social-links',
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import('src/views/pages/Dashboard/StaticManagement/SocialLinks'),
    ),
  },

  {
    exact: true,
    path: '/edit-links',
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import('src/views/pages/Dashboard/StaticManagement/SocialLinksEdit'),
    ),
  },

  {
    exact: true,
    path: '/view-content',
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import('src/views/pages/Dashboard/StaticManagement/About'),
    ),
  },
  {
    exact: true,
    path: '/view-content-user',

    layout: HomeLayout,
    component: lazy(() =>
      import('src/views/pages/Dashboard/StaticManagement/User/AboutUs'),
    ),
  },
  {
    exact: true,
    path: '/view-announcements',
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import('src/views/pages/Dashboard/StaticManagement/Announcements'),
    ),
  },

  {
    exact: true,
    path: '/edit-announcements',
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import('src/views/pages/Dashboard/StaticManagement/AnnouncementsEdit'),
    ),
  },

  {
    exact: true,
    path: '/edit-content',
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import('src/views/pages/Dashboard/StaticManagement/AboutEdit'),
    ),
  },

  {
    exact: true,
    path: '/view-terms',
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import('src/views/pages/Dashboard/StaticManagement/TermsCondition'),
    ),
  },

  {
    exact: true,
    path: '/edit-terms',
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import('src/views/pages/Dashboard/StaticManagement/TermsConditionEdit'),
    ),
  },

  {
    exact: true,
    path: '/policy',
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import('src/views/pages/Dashboard/StaticManagement/PrivacyPolicy'),
    ),
  },

  {
    exact: true,
    path: '/edit-policy',
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import('src/views/pages/Dashboard/StaticManagement/PrivacyPolicyEdit'),
    ),
  },
  {
    exact: true,
    path: '/add-terms',
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import('src/views/pages/Dashboard/StaticManagement/AddTerms'),
    ),
  },
  {
    exact: true,
    path: '/add-policy',
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import('src/views/pages/Dashboard/StaticManagement/AddPolicy'),
    ),
  },

  {
    exact: true,
    path: '/view-terms',
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import('src/views/pages/Dashboard/StaticManagement/TermsCondition'),
    ),
  },

  {
    exact: true,
    path: '/add-token',
    guard: true,
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/Dashboard/KYC')),
  },
  {
    exact: true,
    path: '/user-kyc',
    guard: true,
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/Dashboard/userKyc/index')),
  },

  {
    exact: true,
    path: '/add-kyc1',
    guard: true,
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/Dashboard/AddKyc')),
  },
  {
    exact: true,
    path: '/terms-condition',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/TermsAndConditions')),
  },
  {
    exact: true,
    path: '/terms-services',
    layout: HomeLayout,
    component: lazy(() =>
      import('src/views/pages/TermsAndConditions/TermsServices'),
    ),
  },
  {
    exact: true,
    path: '/privacy-policy',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/Privacy')),
  },
  {
    exact: true,
    path: '/cookies',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/Cookies')),
  },
  {
    exact: true,
    path: '/token-kyc',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/TokenKyc/index')),
  },
  {
    exact: true,
    path: '/announcement',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/announcement/Announcement')),
  },
  {
    exact: true,
    path: '/token-kyc-approved',
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/Kycpending/ApproveTokeKyc')),
  },

  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFound')),
  },
  {
    component: () => <Redirect to="/404" />,
  },
]
