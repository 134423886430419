import _ from 'lodash'
import { colors, createMuiTheme, responsiveFontSizes } from '@material-ui/core'
import typography from './typography'
import { softShadows, strongShadows } from './shadows'

const baseOptions = {
  typography,
  overrides: {
    MuiSelect: {
      selectMenu: { color: 'white' },
      select: {
        '&.MuiSelect-outlined': {
          height: '14px',
        },
      },
    },
    MuiPaginationItem: {
      root: {
        color: 'grey',
      },
    },
    MuiSlider: {
      root: {
        color: '#157af0',
      },
    },
    MuiMenuItem: {
      root: {
        width: 'auto',
        overflow: 'hidden',
        fontSize: '12px',
        boxSizing: 'border-box',
        minHeight: '48px',
        fontFamily: "'Poppins', sans-serif",
        fontWeight: '400',
        lineHeight: '1.5',
        paddingTop: '6px',
        whiteSpace: 'nowrap',
        paddingBottom: '6px',
        // backgroundColor: "grey",
        color: 'white',
      },
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: '#545252',
          color: '#fff',
        },
      },
    },
    MuiInput: {
      underline: {
        '&::after': {
          display: 'none',
        },
      },
    },
    MuiAppBar: {
      positionFixed: {
        top: '0',
        left: 'auto',
        right: '0',
        position: 'fixed',
        background: '#242538',
      },
    },
    MuiTableCell: {
      root: {
        display: 'table-cell',
        fontSize: '13px',
        textAlign: 'center',
        fontFamily: "'Poppins', sans-serif",
        fontWeight: '300',
        lineHeight: '1.43',
        paddingTop: '7px',
        borderBottom: '1px solid rgb(224, 224, 224)',
        paddingBottom: '7px',
        verticalAlign: 'inherit',
      },
    },
    MuiTableContainer: {
      root: {
        overflowX: 'auto',
      },
    },
    MuiFormLabel: {
      root: { color: '#222' },
      colorSecondary: {
        '&.Mui-focused': {
          color: '#222',
        },
      },
    },

    MuiListSubheader: {
      root: {
        color: '#000000',
        fontSize: '22px !important',
        fontWeight: '600 !important',
        lineHeight: '33px !important',
      },
    },

    MuiOutlinedInput: {
      colorSecondary: {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          color: '#222',
        },
        '&.Mui-focused': {
          color: '#222',
        },
      },
      notchedOutline: {
        borderColor: 'none',
      },
      inputMarginDense: {
        paddingTop: '24.5px',
        paddingBottom: '24.5px',
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: 'none',
      },
      outlined: {
        padding: '20px',
        width: '100%',
        border: 'none',
      },
      rounded: {
        borderRadius: '7px',
      },
      elevation1: {
        boxShadow: 'rgb(90 114 123 / 11%) 0px 7px 30px 0px',
      },
    },
    MuiList: {
      padding: {
        paddingLeft: '0px',
        paddingTop: '0px',
        paddingBottom: '0px',
      },
    },
    MuiListItem: {
      root: {
        alignItems: 'self-start',
      },
      gutters: {
        paddingLeft: 0,
      },
    },
    MuiCheckbox: {
      root: {
        padding: '4px',
        fontSize: '12px',
      },
      colorSecondary: {
        '&.Mui-checked': { color: '#000' },
      },
    },
    MuiDropzoneArea: {
      text: {
        marginTop: 0,
        marginBottom: 0,
        fontWeight: 200,
      },
      root: {
        width: '100%',
        maxWidth: '50%',
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      icon: {
        backgroundColor: '#f5f7fa',
        color: '#5a86ff',
        padding: '20px',
      },
    },

    MuiFormControl: {
      root: {
        border: '0',
        margin: '0',
        display: 'inline-flex',
        padding: '0',
        position: 'relative',
        minWidth: '0',
        flexDirection: 'column',
        verticalAlign: 'top',
        width: '100%',
      },
    },

    MuiFormControlLabel: {
      root: {
        paddingBottom: '0',
      },
    },
    MuiListItemSecondaryAction: {
      root: {
        right: 0,
      },
    },
    MuiTableCell: {
      head: {
        // color: '#52565c',
        fontWeight: '500',
        lineHeight: '1.5rem',
      },
      root: {
        fontSize: '13px',
        color: '#595959',
        paddingBottom: '7px',
        paddingTop: '7px',
        // border: '1px solid rgb(224, 224, 224)',
        borderBottom: '1px solid rgb(224, 224, 224)',
        borderTop: '1px solid rgb(224, 224, 224)',
      },
      stickyHeader: {
        backgroundColor: 'none',
        color: '#9f9f9f',
        fontWeight: '300',
        fontSize: '14px',
      },
    },
    MuiDialog: {
      paperScrollPaper: {
        Width: '100%',
        maxWidth: '100%',
      },
      // .MuiDialog-paperWidthSm
      paperScrollPaper: {
        width: '100%',

        display: 'flex',
        maxWidth: '100%',
        maxHeight: 'calc(100% - 64px)',
        flexDirection: 'column',
      },
      paper: {
        overflowY: 'unset',
        backgroundColor: '#fff',
      },
      paperWidthSm: {
        maxWidth: '500px !important',
      },
    },

    MuiInputBase: {
      root: {
        fontSize: '19px',
        color: '#666666',
      },
      input: {
        fontSize: 14,
        color: 'white',
        height: '0.1876em',
      },
    },
    MuiBackdrop: {
      root: { backgroundColor: 'rgba(0, 0, 0, 0.75)' },
    },
    MuiButton: {
      root: {
        color: '#300760',
        minWidth: '0px',
        //padding: '6px 60px',
        borderRadius: 'none',
      },
      text: {
        padding: '3px 3px ',
      },
      label: {
        fontSize: '14px',
        fontWeight: '400',
      },
      containedSecondary: {
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '4px',
        color: '#fff',
        fontSize: '14px',
        backgroundColor: '#f30065',
        padding: '5px 19px',
      },

      containedPrimary: {
        backgroundColor: 'rgb(90 134 255)',
        color: '#fff',
        fontSize: '15px',
        lineHeight: ' 21px',
        height: '50px',
        minWidth: '100px',
        '&:hover': {
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          color: '#fff',
          backgroundColor: 'rgb(90 134 255)',
        },
      },
      outlinedPrimary: {
        color: 'rgb(90 134 255)',
        fontWeight: 600,
        height: '50px',
        minWidth: '100px',
        border: '1px solid rgb(90 134 255)',
        '&:hover': {
          backgroundColor: 'rgb(90 134 255)',
          border: '1px solid rgb(90 134 255)',
          color: '#fff',
        },
      },
      outlinedSecondary: {
        color: '#00e0b0',
        border: '1px solid rgba(0, 224, 176, 0.5)',
      },
      outlinedSizeSmall: {
        padding: '6px 23px',
        fontSize: '16px',
        lineHeight: ' 24px',
      },
      contained: {
        '&.Mui-disabled': {
          backgroundColor: 'rgb(158 180 241)',
          color: '#fff',
        },
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: '0',
      },
    },
    MuiMenu: {
      paper: { top: '47px', background: '#000' },
      list: {
        // backgroundColor: "#717476",
        // color: "#000 !important",
        padding: '10px',
      },
    },

    MuiTypography: {
      body1: {
        fontSize: '15px',
        fontFamily: "'Poppins', sans-serif",
        fontWeight: '300',
        lineHeight: '1.5',
      },
      subtitle1: {
        color: '#000',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: ' 16px',
        colorSecondary: {
          color: '#8d8989',
        },
      },
    },
  },
}

const themesOptions = [
  {
    name: 'DARK',
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: '#1f73b7',
          },
        },
      },
      MuiSelectOutlined: {
        MuiSelectOutlined: {
          color: '#fff',
        },
      },
    },
    MuiPaginationItem: {
      root: {
        color: '#fff',
      },
    },
    typography: {
      fontWeight: 400,
      fontFamily: "'Poppins', sans-serif",
    },
    palette: {
      type: 'light',
      action: {
        primary: '#20509e',
      },
      background: {
        default: '#1e1f2e',
        dark: '#1e1f2e',
        dark1: '#161515',
        paper: '#ccc',
        dark2: 'rgb(66 64 64)',
        footercolor: '#4c4949',
        lightblack: '#282727',
        subfootercolor: '#221f1f',
        circleBoxColor: '#424040',
        addBox: '#424040',
        blueBox: '#424040',
        border: 'rgb(66 64 64)',
      },

      primary: {
        main: '#fff',
        dark: '#fff',
        light: '#f5f5f5',
      },
      secondary: {
        main: '#fff',
      },
      warning: {
        main: '#ffae33',
        dark: '#ffae33',
        light: '#fff1dc',
      },
      success: {
        main: '#54e18c',
        dark: '#54e18c',
        light: '#e2faec',
      },
      error: {
        main: '#ff7d68',
        dark: '#ff7d68',
        light: '#ffe9e6',
      },
      text: {
        primary: '#000',
        secondary: '#fff',
        black: '#fff',
      },
      common: {
        black: '#222222',
      },
    },
    shadows: softShadows,
  },
  {
    name: 'LIGHT',
    typography: {
      fontWeight: 400,
      fontFamily: "'Poppins', sans-serif",
    },
    MuiSelect: {
      selectMenu: {
        color: '#000',
        background: 'red',
      },
    },
    MuiPaginationItem: {
      root: {
        color: '#000',
      },
    },

    palette: {
      type: 'dark',
      action: {
        primary: '#222222',
      },
      background: {
        default: '#fff',
        dark: '#fff',
        dark2: 'rgb(240, 246, 250)',
        dark1: '#fff',
        paper: '#f5f5f5',
        lightblack: '#f5f7fa',
        footercolor: '#242538',
        subfootercolor: '#1e1f2e',
        circleBoxColor: '#fff9fa',
        riskybox: '#7a7a7a',
        addBox: '#eaf2f7',
        blueBox: '#dbf1ff',
        border: '#e1e1e1',
      },
      primary: {
        main: '#222222',
        dark: '#222222',
        light: '#222222',
      },

      secondary: {
        main: '#222222',
      },
      warning: {
        main: '#ffae33',
        dark: '#ffae33',
        light: '#fff1dc',
      },
      success: {
        main: '#54e18c',
        dark: '#54e18c',
        light: '#e2faec',
      },
      error: {
        main: '#ff7d68',
        dark: '#ff7d68',
        light: '#ffe9e6',
      },
      text: {
        primary: '#000',
        secondary: '#fff',
        black: '#000',
      },
      common: {
        black: '#222222',
      },
    },
    shadows: strongShadows,
  },
]

export const createTheme = (config = {}) => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme)

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`))
    ;[themeOptions] = themesOptions
  }

  let theme = createMuiTheme(
    _.merge({}, baseOptions, themeOptions, { direction: config.direction }),
  )

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme)
  }

  return theme
}
