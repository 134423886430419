import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Grid, Box, Container } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  content: {
    height: '100vh',
    overflowX: 'auto',
    background: theme.palette.background.dark1,
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      overflow: 'unset',
    },
    padding: '0',
  },
  left: {
    height: '100vh',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
    [theme.breakpoints.down('xs')]: {
      // display:"none",
    },
  },
  box: {
    background: theme.palette.background.lightblack,

    //boxShadow: 'rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px',
    // [theme.breakpoints.down('sm')]: {
    //   paddingBottom: '50px',
    // },
  },
  headBox: {
    background: theme.palette.background.dark1,
  },
}))

const LoginLayout = ({ children }) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Grid
      container
      alignItems="center"
      // style={{
      //   background: '#282727',
      // }}
    >
      <Grid item xs={12} sm={12} md={5} className={classes.left}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
          className={classes.box}
        >
          <img
            src="/images/logo/logo-shape.svg"
            style={{
              width: '80%',
              maxWidth: 270,
              margin: '20px 0',
              cursor: 'pointer',
            }}
            alt=""
            onClick={() => history.push('/')}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={7} className={classes.headBox}>
        <Container maxWidth="sm">
          <Box className={classes.content}>{children}</Box>
        </Container>
      </Grid>
    </Grid>
  )
}

LoginLayout.propTypes = {
  children: PropTypes.node,
}

export default LoginLayout
