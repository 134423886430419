import React, { useState, useEffect, useContext } from 'react'
import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
  Button,
  ListItem,
  List,
} from '@material-ui/core'
import TwitterIcon from '@material-ui/icons/Twitter'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import YouTubeIcon from '@material-ui/icons/YouTube'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import IconButton from '@material-ui/core/IconButton'
import Brightness3Icon from '@material-ui/icons/Brightness3'
import Brightness7Icon from '@material-ui/icons/Brightness7'
import typography from 'src/theme/typography'
import { createMuiTheme } from '@material-ui/core/styles'
import { AuthContext } from 'src/context/Auth'
import SettingsContext from 'src/context/SettingsContext'
import ThemeButton from 'src/component/ToggleButton/ThemeButton'
const useStyles = makeStyles((theme) => ({
  socialBox: {
    background: theme.palette.background.subfootercolor,

    '& ul': {
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      '& li': {
        width: 'auto',
        padding: '5px',
        '& a': {
          color: '#fff',
          textDecoration: 'none',
          fontSize: '14px',
          fontWeight: 300,
          '&:hover': {
            color: '#5a86ff',
          },
          '& svg': {
            fontSize: '20px',
            [theme.breakpoints.down('xs')]: {
              fontSize: '17px',
            },
          },
        },
      },
    },
    '& .leftBox': {
      justifyContent: 'flex-end',
      marginTop: '6px',
    },
    '& .submit': {
      backgroundColor: '#5a86ff',
      paddingLeft: '5px',
      paddingRight: '5px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '12px',
      },
      '&:hover': {
        color: '#fff',
      },
    },
  },
  toggleButtonShell: {
    // border: '1px solid #000',
    borderRadius: '32px',
    width: '50px',
    // backgroundColor: '#000',
    height: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '4px 3px',
  },
  toggleButton: {
    border: '1px solid #000',
    borderRadius: '100%',
    display: 'flex',
    padding: '6px',
    cursor: 'pointer',
  },
  socialIconTab: {
    color: '#96f',
  },
}))

export default function Liquidity({ auth, history }) {
  const classes = useStyles()
  const [theme, setTheme] = useState(true)
  // const auth = useContext(AuthContext)
  const themeSeeting = useContext(SettingsContext)

  return (
    <Box className={classes.socialBox} id="blockDetails">
      <Container>
        <Grid container alignItems="center">
          <Grid
            item
            xs={6}
            sm={6}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {/* <Box>
              <List>
                <ListItem>
                  <Link
                    to={{
                      pathname: '/add-token',
                      search: 'consolidated',
                    }}
                    className="submit"
                  >
                    Submit Your Token
                  </Link>
                </ListItem>
              </List>
            </Box> */}

            <Box>
              {' '}
              <Typography variant="h4" style={{ color: '#fff' }}>
                Beta Version
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <List className="leftBox">
                <ListItem>
                  <a href={auth?.socialLinks?.instagramLink} target="_blank">
                    <InstagramIcon className={classes.socialIconTab} />
                  </a>
                </ListItem>
                <ListItem>
                  <a href={auth?.socialLinks?.twitterLink} target="_blank">
                    <TwitterIcon className={classes.socialIconTab} />
                  </a>
                </ListItem>
                <ListItem>
                  <a href={auth?.socialLinks?.facebookLink} target="_blank">
                    <FacebookIcon className={classes.socialIconTab} />
                  </a>
                </ListItem>
                <ListItem>
                  <a href={auth?.socialLinks?.youtubeLink} target="_blank">
                    <YouTubeIcon className={classes.socialIconTab} />
                  </a>
                </ListItem>
              </List>
              <ThemeButton />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
